.iframe_map {
  width: 100vw;
  height: 85vh;
}

.data_div {
  margin-top: 5rem;
}
@media screen and (max-width: 768px) {
  .data_div {
    margin-top: 10px;
  }
}

.data_div {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.data_div > p {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  margin-right: 15vw;
  margin-left: 15vw;
  text-align: center;
}
@media screen and (min-width: 992px) {
  .data_div > p {
    margin-right: 25vw;
    margin-left: 25vw;
  }
}

.data_div > button {
  border-radius: 0;
}
