@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3rem;

  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --hue: 0;
  --sat: 0%;
  --title-color: hsl(var(--hue), var(--sat), 20%);
  --title-color-dark: hsl(var(--hue), var(--sat), 0%);
  --text-color: hsl(var(--hue), var(--sat), 46%);
  --body-color: hsl(var(--hue), var(--sat), 98%);
  --container-color: #fff;

  /*========== Font and typography ==========*/
  --body-font: "Poppins", sans-serif;

  /*.5rem = 8px | 1rem = 16px ...*/
  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;

  /*========== Font weight ==========*/
  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== Margenes Bottom ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/* Responsive typography */
@media screen and (max-width: 992px) {
  :root {
    --big-font-size: 2.75rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/*=============== BASE ===============*/

/*=============== REUSABLE CSS CLASSES ===============*/
.sectiont {
  padding: 6rem 0 2rem;
}

.section__titlet {
  font-size: var(--h1-font-size);
  color: var(--title-color);
}

.section__subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: 4rem;
}

.section__titlet,
.section__subtitle {
  text-align: center;
}

/*=============== LAYOUT ===============*/
.containert {
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}
.add-div {
  display: flex;
  justify-content: center;
  max-width: fit-content;
  margin: auto;
  margin-bottom: 2.5rem;
}

.add-new {
  margin-top: 1.5px;
  height: 15px;
  cursor: pointer;
}

.add-text {
  cursor: pointer;
  display: block;
  font-size: var(--small-font-size);
  transition: ease 0.3s all;
}

.add-text:hover {
  color: rgb(7, 38, 67);
  transform: scale(1.1);
}

/*=============== BUTTONS ===============*/

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .containert {
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .sectiont {
    padding: 2rem 0 4rem;
  }
}

@media screen and (max-width: 576px) {
}

/* For small devices */
@media screen and (max-width: 350px) {
  :root {
    --big-font-size: 2.25rem;
  }

  .containert {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
}

.testimonials__container {
  width: 900px;
}

.testimonials__card {
  background-color: aliceblue;
  border: 2px solid rgba(0, 0, 0, 0.1);
  padding: 1.5rem 2rem;
  border-radius: 1.5rem;
  margin-bottom: var(--mb-3);
}

.testimonials__img {
  width: 40px;
  height: 40px;
  border-radius: 3rem;
  margin-bottom: var(--mb-1);
  border-radius: 50%;
}

.testimonials__name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-25);
}

.testimonials__description {
  font-size: var(--small-font-size);
}

.swiper-pagination-bullet {
  background-color: var(--text-color) !important;
}

.swiper-pagination-active {
  background-color: var(--title-color) !important;
}

@media screen and (max-width: 992px) {
  .testimonials__container {
    width: 90%;
  }
  .testimonials__card {
    padding: 1.25rem 1.5rem;
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .testimonials__container {
    width: 90%;
  }
}

@media screen and (max-width: 576px) {
}

/* For small devices */
@media screen and (max-width: 350px) {
}

/*=============== GOOGLE FONTS ===============*/
