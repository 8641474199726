.modal {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  margin-bottom: 4rem;
}

.close-button {
  cursor: pointer;
  height: 15px;
  margin-top: -1rem;
  margin-left: 95%;
  transition: ease 0.3s all;
}

.close-button:hover {
  transform: scale(1.2);
}
.modal > .form {
  background-color: #fff;
  box-shadow: 0 10px 60px aliceblue;
  border: 1px solid rgb(159, 159, 160);
  border-radius: 20px;
  padding: 2rem 0.7rem 0.7rem 0.7rem;
  text-align: center;
  font-size: 1.125rem;
  max-width: 320px;
}

.form-title {
  color: #000000;
  font-size: 1.8rem;
  font-weight: 500;
}

.form-paragraph {
  margin-top: 10px;
  font-size: 0.9375rem;
  color: rgb(105, 105, 105);
}

.drop-container {
  background-color: #fff;
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 0.6rem;
  border-radius: 10px;
  border: 2px dashed rgb(171, 202, 255);
  color: #444;
  cursor: pointer;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}

.drop-container:hover {
  background: rgba(0, 140, 255, 0.164);
  border-color: rgba(17, 17, 17, 0.616);
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color 0.2s ease-in-out;
}

#file-input {
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 2px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid rgba(8, 8, 8, 0.288);
}

#file-input::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #084cdf;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

#file-input::file-selector-button:hover {
  background: #0d45a5;
}

.form-text {
  width: 380px;
  margin-top: 0.7rem;
  margin-bottom: 0.6rem;
  max-width: 100%;
  color: #444;
  padding: 2px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid rgba(8, 8, 8, 0.288);
  font-size: 16px;
}

.form-textarea {
  width: 380px;
  font-size: 16px;
  margin-bottom: 0.4rem;
  max-width: 100%;
  color: #444;
  padding: 2px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid rgba(8, 8, 8, 0.288);
  resize: none;
  height: 5rem;
}

.submit-Button {
  margin-top: 1.7rem;
  color: black;
  font-weight: 500;
  border-radius: 0.5rem;
  font-size: 1rem;
  line-height: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  text-align: center;
  margin-right: 0.5rem;
  display: inline-flex;
  align-items: center;
  border: none;
  transition: all 0.3s ease-in;
}
.submit-Button:hover {
  color: white;
  background-color: #1e40af;
}
