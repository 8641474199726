@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.container1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 40px 20px 0 20px;
}

.container1 .heading1 {
  width: 50%;
  padding-bottom: 50px;
}

.container1 .heading1 h3 {
  font-size: 3em;
  font-weight: bolder;
  padding-bottom: 10px;
  border-bottom: 3px solid #222;
}

.container1 .heading1 h3 span {
  font-weight: 100;
}

/* .container .box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(33%, 1fr));
  grid-gap: 20px;
  justify-items: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-top: 20px;
}

.container .box .dream {
  width: 100%;
}

.container .box .dream img {
  width: 100%;
  height: auto;
  object-fit: cover;
  padding-bottom: 15px;
  border-radius: 5px;
}

.imagesgallery {
  width: 100%;
  padding-bottom: 15px;
  border-radius: 5px;
}

.container .btn {
  margin: 40px 0 70px 0;
  background: #222;
  padding: 15px 40px;
  border-radius: 5px;
}

.container .btn a {
  color: #fff;
  font-size: 1.2em;
  text-decoration: none;
  font-weight: bolder;
  letter-spacing: 3px;
}

@media only screen and (max-width: 1200px) {
  .container .box {
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  }
}

@media only screen and (max-width: 767px) {
  .container .box {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    margin-top: 10px;
  }
} */
@import "https://unpkg.com/open-props";

.gallery__img {
  width: 100%;
  display: block;
  box-shadow: 1px 4px 45px -6px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 1px 4px 45px -6px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 1px 4px 45px -6px rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  border: 1px solid #ccc;
  transition: transform 0.3s ease;
}
.gallery__img:hover {
  transform: scale(1.03);
}

.page {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas: "header" "main";
  color: black;
  background-color: white;
  min-height: 100vh;
  font-family: var(--font-sans);
}
.page__header {
  grid-area: header;
}
.page__main {
  grid-area: main;
}

.header {
  padding: var(--size-9) var(--size-6);
  text-align: center;
}
.header__title {
  margin-bottom: var(--size-6);
  font-size: var(--font-size-fluid-2);
  font-weight: var(--font-weight-6);
}
.header__desc {
  font-size: var(--font-size-fluid-1);
  line-height: var(--font-lineheight-2);
}

.main {
  display: grid;
  grid-template-areas: "gallery";
}
.main__gallery {
  grid-area: gallery;
}

.gallery {
  display: flex;
  gap: var(--size-2);
  justify-content: center;
  flex-wrap: wrap;
  padding: var(--size-2);
  padding-bottom: 10em;
}
.gallery::before {
  position: fixed;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 5vh;
}
.gallery__col {
  flex: calc(25% - var(--size-2));
  max-width: calc(25% - var(--size-2));
}
@media screen and (max-width: 48em) {
  .gallery__col {
    flex: calc(50% - var(--size-2));
    max-width: calc(50% - var(--size-2));
  }
}
@media screen and (max-width: 36em) {
  .gallery__col {
    flex: calc(100% - var(--size-2));
    max-width: calc(100% - var(--size-2));
  }
}
.gallery__img {
  margin-bottom: var(--size-3);
}

.gallery__section {
  margin-top: 4.7rem;
}

@media screen and (max-width: 768px) {
  .gallery__section {
    margin-top: 0.3rem;
  }
}
