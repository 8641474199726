.title1 {
  margin-top: 1rem;
  text-align: center;
}
.text1 {
  margin-top: 2rem;
  text-transform: uppercase;
  background: linear-gradient(to right, #30cfd0 0%, #330867 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3.6vw;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
@media screen and (min-width: 280px) and (max-width: 768px) {
  .text1 {
    margin-top: 3rem;
    font-size: 7.3vw;
  }
}
