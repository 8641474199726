.price-div {
  display: flex;
  justify-content: center;
}
.actual-tag {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.actual-tag > p {
  font-size: 1.2rem;
}

.actual-tag > p > span {
  font-size: 1.6rem;
  font-weight: bold;
  background-image: linear-gradient(to right, #185555 0%, #330867 100%);
  -webkit-background-clip: text; /* For Webkit/Blink browsers like Chrome and Safari */
  background-clip: text;
  color: transparent;
}

.discounted-tag {
  margin-bottom: 1rem;
}

.discounted-tag > p {
  font-size: 1.2rem;
}

.discounted-tag > p > span {
  font-size: 1.6rem;
  font-weight: bold;
  background-image: linear-gradient(to right, #185555 0%, #330867 100%);
  -webkit-background-clip: text; /* For Webkit/Blink browsers like Chrome and Safari */
  background-clip: text;
  color: transparent;
}

.discounted-price {
  font-size: 1.5rem;
  font-weight: bold;
}
.actual-price-discounted > span {
  text-decoration: black line-through;
}
.book-now {
  display: inline-block;
  border-radius: 4px;
  background-color: #3d405b;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 17px;
  padding: 12px;
  width: 130px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 2px;
}

.book-now > span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.book-now > span:after {
  content: "»";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -15px;
  transition: 0.5s;
}

.book-now:hover > span {
  padding-right: 15px;
}

.book-now:hover > span:after {
  opacity: 1;
  right: 0;
}

.distance {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
