/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3rem;

  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --hue: 0;
  --sat: 0%;
  --title-color: hsl(var(--hue), var(--sat), 20%);
  --title-color-dark: hsl(var(--hue), var(--sat), 0%);
  --text-color: hsl(var(--hue), var(--sat), 46%);
  --body-color: hsl(var(--hue), var(--sat), 98%);
  --container-color: #fff;

  /*========== Font and typography ==========*/
  --body-font: "Poppins", sans-serif;

  /*.5rem = 8px | 1rem = 16px ...*/
  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;

  /*========== Font weight ==========*/
  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== Margenes Bottom ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/*=============== REUSABLE CSS CLASSES ===============*/

/*=============== LAYOUT ===============*/
.containerf {
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .containerf {
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  :root {
    --big-font-size: 2.25rem;
  }

  .containerf {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
}

.footer {
  background-color: var(--container-color);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer__container {
  padding: 2rem 0 2rem;
}

.footer__title,
.footer__link {
  color: var(--title-color);
}

.footer__title {
  text-align: center;
  margin-bottom: var(--mb-2);
}

.footer__link:hover {
  color: var(--title-color-dark);
}

.footer__list {
  display: flex;
  justify-content: center;
  column-gap: 1.5rem;
  margin-bottom: var(--mb-2);
}

.footer__social {
  display: flex;
  justify-content: space-around;
  column-gap: 1.25rem;
  border-top: 0.5px solid grey;
  padding-top: 2rem;
}

.terms {
  display: flex;
  flex-direction: column;
}
.terms-heading {
  color: var(--title-color);
  text-align: center;
  font-size: 2rem;
  padding-bottom: 1rem;
  background-image: linear-gradient(to right, #185555 0%, #330867 100%);
  background-clip: text;
  -webkit-background-clip: text; /* For webkit-based browsers like Chrome and Safari */
  color: transparent;
}
.heading-div {
  display: flex;
  justify-content: center;
}
.terms-image {
  height: 30px;
  width: 30px;
  margin-top: 0.5rem;
}
.terms-desc {
  font-size: 0.9rem;
}

.contact {
  display: flex;
  flex-direction: column;
}

.contact-heading {
  color: var(--title-color);
  text-align: center;
  font-size: 2rem;
  padding-bottom: 1rem;
  background-image: linear-gradient(to right, #185555 0%, #330867 100%);
  background-clip: text;
  -webkit-background-clip: text; /* For webkit-based browsers like Chrome and Safari */
  color: transparent;
}

.phone-number {
  font-size: 0.9rem;
}

.footer__social-link {
  background-color: var(--title-color);
  color: var(--container-color);
  font-size: 1.125rem;
  padding: 0.4rem;
  border-radius: 0.5rem;
  display: inline-flex;
}

.footer__social-link:hover {
  background-color: var(--title-color-dark);
}

.footer__copy {
  display: block;
  margin-top: 4.5rem;
  color: var(--title-color);
  text-align: center;
  font-size: var(--smaller-font-size);
}

@media screen and (max-width: 959px) {
  .footer__social {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    column-gap: 1.25rem;
    border-top: 0.5px solid grey;
    padding-top: 2rem;
  }

  .terms {
    padding-bottom: 2rem;
  }
  .contact {
    border-top: 0.5px solid grey;
    padding-top: 2rem;
  }
}
@media screen and (max-width: 992px) {
  .footer__social-link {
    padding: 0.25rem;
    border-radius: 0.25rem;
    font-size: 1rem;
  }
}

.main__footer {
  text-decoration: none;
}

@media screen and (max-width: 650px) {
  .footer__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    column-gap: 1.5rem;
    margin-bottom: var(--mb-2);
  }
  .footer__list > li {
    margin-bottom: 0.7rem;
  }
}

.header__logof {
  margin-top: 2rem;
  width: 100px;
  position: relative;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
}
